/* ===================== */
/*       CONTAINER       */
/* ===================== */
.slides-container {
  width: 150%;
  max-width: 1200px;
  height: 96vh;
  background: radial-gradient(circle, #ffffff, #b3ecff);
  border-radius: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  z-index: 1001;
}

/* ===================== */
/*     PROGRESS BAR      */
/* ===================== */
.progress-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background: #38b2ac;
  transition: width 0.3s ease;
}

/* ===================== */
/*      CLOSE BUTTON     */
/* ===================== */
.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  padding: 8px;
  border-radius: 50%;
  color: black;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 10;
}

.close-button:hover {
  transform: scale(1.1);
}

/* ===================== */
/*   SPECIAL SLIDES      */
/*   (Intro & Outro)     */
/* ===================== */
.special-slide {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
}

.special-slide h1 {
  font-size: 2.5rem;
  color: #2d3748;
  margin: 1rem 0;
}

.special-slide p {
  font-size: 1.2rem;
  color: #4a5568;
  max-width: 800px;
  margin: 0.5rem auto 1rem;
  line-height: 1.6;
  text-align: left;
  padding: 0 2rem;
}

/* ===================== */
/*  LESSON IMAGE (Slide 1) */
/* ===================== */
.lesson-image {
  width: 60%;
  max-width: 690px;
  display: block;
  margin: 1rem auto;
  position: relative;
  top: -5px;
  height: auto;
}

/* ===================== */
/*   REGULAR SLIDES      */
/* ===================== */
.regular-slide {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  overflow-y: hidden;
}

.slide-header {
  margin-bottom: 1rem;
}

.slide-header h2 {
  font-size: 1.8rem;
  color: #2d3748;
  margin: 1rem 0;
}

.slide-header p {
  font-size: 1.1rem;
  color: #4a5568;
  line-height: 1.6;
  margin-bottom: 0;
}

/* ===================== */
/*  SPLIT VISUAL LAYOUT  */
/* (Slides 2, 3 & 4)     */
/* ===================== */
.split-visual {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
  align-items: center;
  height: 60vh;
}

.visual-section {
  flex: 1;
  text-align: center;
  padding: 1rem;
}

/* ===================== */
/* STRUCTURE IMAGE &     */
/* HIGHLIGHT BOX (Slide 2)*/
/* ===================== */
.structure-image {
  max-width: 35%;
  height: auto;
  border: 3px solid #6366f1;
  border-radius: 12px;
  padding: 0;
  margin: 0 auto;
}

/* ===================== */
/*   REDUCED IMAGE (Slide 3) */
/* ===================== */
.reduced-image {
  max-width: 81%; /* Reduced width */
  height: auto;
  object-fit: contain;
  margin: 0 auto;
}

/* ===================== */
/*   HIGHLIGHT BOX       */
/*    (Slide 2)          */
/* ===================== */
.highlight-box {
  background: #f0f4ff;
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem auto;
  border: 2px solid #6366f1;
  font-weight: 500;
  max-width: 80%;
}

/* ===================== */
/*  CREATIVE TAG EXAMPLE */
/*       (Slide 4)       */
/* ===================== */
.tag-example {
  background: #f9fafb;
  padding: 1rem;
  border: 2px dashed #38b2ac;
  border-radius: 8px;
  margin-top: 1rem;
  text-align: left;
}

.code-block {
  background: #e2e8f0;
  padding: 0.5rem;
  border-radius: 4px;
  font-family: monospace;
  margin-top: 0.5rem;
}

.code-key {
  font-weight: bold;
}

/* ===================== */
/*     NAVIGATION        */
/* ===================== */
.slide-navigation {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  gap: 1rem;
}

/* Arrow Buttons (◀ ▶) - Background set to black */
.nav-arrow {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background: black;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-arrow:hover:not(:disabled) {
  transform: scale(1.1);
  background: #333; /* Slight dark gray on hover */
}

.nav-arrow:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* ===================== */
/*      NEXT HINT        */
/* ===================== */
.next-hint {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #38b2ac;
  font-size: 1rem;
  animation: bounce 2s infinite;
}

/* ===================== */
/*       ANIMATIONS      */
/* ===================== */
@keyframes float {
  0% {
    transform: translateX(-50%) translateY(0px);
    box-shadow: 0 12px 36px rgba(99, 102, 241, 0.25);
  }
  50% {
    transform: translateX(-50%) translateY(-20px);
    box-shadow: 0 20px 48px rgba(99, 102, 241, 0.35);
  }
  100% {
    transform: translateX(-50%) translateY(0px);
    box-shadow: 0 12px 36px rgba(99, 102, 241, 0.25);
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1) translateX(0);
    opacity: 1;
  }
  50% {
    transform: scale(1.2) translateX(8px);
    opacity: 0.8;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
}

/* ===================== */
/*       RESPONSIVE      */
/* ===================== */
@media (max-width: 768px) {
  .slides-container {
    width: 95%;
    height: 90vh;
  }
  
  .split-visual {
    flex-direction: column;
    height: auto;
  }
  
  .hint-message {
    left: 50%;
    bottom: 120px;
    width: 80%;
    font-size: 1rem;
  }
  
  .structure-image {
    max-width: 70%;
  }
  
  .reduced-image {
    max-width: 100%;
  }
}

/* ===================== */
/*  PRACTICAL 'START'    */
/*        BUTTON         */
/* ===================== */
.start-button {
  background: #4169e1;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
}

/* Removed pseudo-element that added a random symbol */
/*
.start-button::after {
  content: ' 🚀';
  font-size: 1.2rem;
  margin-left: 0.3rem;
}
*/

.start-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.start-button:active {
  transform: translateY(0);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

/* ===================== */
/*      HINT MESSAGE     */
/* ===================== */
.hint-message {
  position: absolute;
  bottom: 68px;
  left: 88%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 4px;
  background: linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%);
  color: white;
  padding: 14px 22px;
  clip-path: polygon(2% 0%, 100% 0%, 90% 85%, 75% 85%, 80% 100%, 79% 85%, 0% 95%);
  box-shadow: 0 12px 36px rgba(99, 102, 241, 0.25);
  font-weight: 500;
  letter-spacing: 0.3px;
  animation: float 3s ease-in-out infinite;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  font-size: 1.1rem;
  padding-bottom: 28px;
  min-width: 270px;
}

.hint-message .chevron {
  color: white;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

/* ===================== */
/*  DARK MODE STYLING    */
/* ===================== */
.dark-mode {
  background: var(--glassmorphism-bg) !important;
  backdrop-filter: blur(20px) !important;
  border-radius: 20px !important;
  border: 1px solid var(--glassmorphism-border) !important;
  box-shadow: var(--neon-glow) !important;
  animation: background-shift 10s infinite alternate !important;
}

.dark-mode .special-slide h1,
.dark-mode .special-slide p,
.dark-mode .slide-header h2,
.dark-mode .slide-header p {
  color: white !important;
}

/* ===================== */
/* DARK MODE TOGGLE BTN  */
/* ===================== */
.dark-mode-button {
  position: absolute;
  top: 20px;
  right: 70px; /* Adjust so it sits nicely beside the close button */
  border: none;
  padding: 8px;
  border-radius: 50%;
  background: var(--glassmorphism-bg);
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 10;
  backdrop-filter: blur(10px);
}

.dark-mode-button:hover {
  transform: scale(1.1);
  background: var(--primary-color);
}

/* ===================== */
/* VOICE ASSISTANT BTN   */
/* ===================== */
.voice-btn {
  position: absolute;
  top: 20px;
  right: 120px; /* Adjust this value so the voice button sits to the left of the dark mode button */
  border: none;
  padding: 8px;
  border-radius: 50%;
  background: var(--glassmorphism-bg);
  cursor: pointer;
  transition: transform 0.3s ease, background 0.3s ease;
  z-index: 10;
  backdrop-filter: blur(10px);
}

.voice-btn:hover {
  transform: scale(1.1);
}

.voice-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.voice-btn.speaking {
  background: #2c9a9a;
  box-shadow: 0 0 10px rgba(56, 178, 172, 0.7);
}

/* ===================== */
/*  VOICE BTN CONTAINER  */
/* ===================== */
.voice-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* ===================== */
/*  SOUND WAVES CONTAINER*/
/* ===================== */
.sound-waves {
  display: flex;
  align-items: flex-end;
  gap: 2px;
  margin-left: 5px;
}

/* ===================== */
/*  SOUND WAVE ELEMENTS  */
/* ===================== */
.wave {
  display: block;
  width: 4px;
  height: 8px;
  background: whitesmoke;
  border-radius: 2px;
  animation: waveAnimation 1s infinite ease-in-out;
}

/* ===================== */
/*  KEYFRAMES FOR WAVES  */
/* ===================== */
@keyframes waveAnimation {
  0%, 100% {
    height: 8px;
  }
  50% {
    height: 16px;
  }
}
