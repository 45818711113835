.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: opacity 0.3s ease;
}

.modal-container {
  background: white;
  width: 840px; /* Doubled the width to accommodate two parts */
  padding: 20px;
  border-radius: 12px;
  display: flex; /* Changed to flex to place two parts side by side */
  position: relative;
  text-align: center;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  animation: fadeIn 0.3s ease;
}

.modal-left,
.modal-right {
  width: 50%;
}

.modal-right img {
  width: 92%;
  height: auto;
  border-radius: 12px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #888;
  transition: color 0.2s;
}

.close-button:hover {
  color: black;
}

.modal-container h2 {
  font-size: 26px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #333;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.modal-container p {
  font-size: 14px;
  color: #666;
  margin: 15px 0;
  font-family: 'Arial', sans-serif;
}

.login-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.login-option {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #ddd;
  padding: 12px;
  border-radius: 6px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.login-option:hover {
  background-color: whitesmoke;
  border-color: #007bff;
  transform: scale(1.05);
}

.login-option .login-icon {
  margin-right: 12px;
  font-size: 20px;
  width: 20px; /* Added width */
  height: 20px; /* Added height */
}

.mo{
  text-align: left;
  font-size: 20px;
}

.terms {
  font-size: 12px;
  color: #888;
  margin-top: 25px;
  text-align: left;
}

.terms a {
  color: #007bff;
  text-decoration: none;
}

.terms a:hover {
  text-decoration: underline;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
