.career-banner {
    display: flex;
    align-items: center;
    background-color: #b3ecff; /* Light blue background */
    padding: 40px;
    gap: 0px;
    border-radius: 10px;
    height: 433px;
    clip-path: ellipse(100% 100% at 50% 0%);
  }
  
  .career-banner-text {
    flex: 1;
    padding-right: 20px;
    padding-top: 0px;
    transform: translateY(-15%);
  }
  
  .career-banner-text h1 {
    text-align: center;
    font-size: 52px;
    color: #2e0c6e;
    margin: 0;
    padding: 0;
  }
  
  .career-banner-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .career-banner-image img {
    max-width: 75%;
    height: auto;
    border-radius: 10px;
  }
  
  .words_container {
    position: relative;
    background-color: #f0f7ff;
    padding: 70px 30px; /* Increased padding for height */
    margin: 40px 20px; /* Added margin for 10px gap on left and right */
    border-radius: 20px; /* Curved edges */
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column; /* Stack content vertically */
    overflow: hidden;
  }
  
  .words_content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    z-index: 1;
  }
  
  .words_heading {
    font-size: 54px;
    color: #2e0c6e;
    margin-bottom: 4px;
    margin-top: 4px;
    font-weight: 600;
  }
  
  .words_text {
    font-size: 22px;
    line-height: 1.6;
    color: #333;
    margin: 0;
    padding: 0 20px;
  }
  
  .words_button {
    margin-top: 20px; /* Space above the button */
    background-color: #007bff;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    padding: 12px 24px; /* Padding for button */
    border: none;
    border-radius: 5px; /* Rounded corners for the button */
    cursor: pointer;
    text-transform: uppercase;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;;
    width: 250px;
  }
  
  .words_button:hover {
    background-color: #007bff; /* Darker shade on hover */
    box-shadow: 0px 8px 15px rgba(74, 27, 139, 0.2); /* Add a shadow effect */
    transform: scale(1.05); /* Slightly increase the size */
  }
  
  .words_flower {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .words_flower img {
    width: auto;
    height: 377px;
    opacity: 0.8;
  }
  
  .words_flower-left {
    left: 10px; /* Adjusted for 10px margin gap */
  }
  
  .words_flower-right {
    right: 10px; /* Adjusted for 10px margin gap */
  }
  
  @media (max-width: 768px) {
    .words_container {
      padding: 50px 20px;
    }
  
    .words_heading {
      font-size: 28px;
    }
  
    .words_text {
      font-size: 16px;
      padding: 0 15px;
    }
  
    .words_button {
      font-size: 16px;
      padding: 10px 20px;
    }
  
    .words_flower img {
      width: 120px;
    }
  }
  
  @media (max-width: 480px) {
    .words_flower img {
      width: 80px;
    }
  
    .words_button {
      font-size: 14px;
      padding: 8px 16px;
    }
  }
  
  .border_design {
    display: inline-block;
    background: url('../../assets/images/ii.png') no-repeat center;
    background-size: contain;
    width: 300px;
    height: 110px;
    padding: 20px; /* Adjust padding as needed */ 
    vertical-align: baseline; /* Move the border higher */ 
    margin-left: -15px;
  }
  