/* Remove any pseudo-elements adding unwanted content specifically for the dot issue */
.slide::before, .slide::after {
    content: none !important; /* Remove unwanted dots or symbols on the poster element */
  }
  /* Set overflow to ensure no hidden content is showing */
  .hh2 {
    font-family: "Noto Sans", sans-serif;
    font-size: 42px;
    text-align: center;
    color: #38444f;
    line-height: 50.4px;
    padding: 0px;
  }
  
  .hhh2 {
    font-family: "Noto Sans", sans-serif;
    font-size: 19px;
    text-align: center;
    color: #626261;
    line-height: 29.45px;
    padding: 0px;
  }
  
  .pp2 {
    margin: 20px auto; /* Center the carousel */
    margin-top: 40px;
    width: 85%; /* Width of the carousel */
  }
  
  /* Carousel Container */
  .poster2 {
    position: relative;
    margin: 20px auto;
    width: 90%;
    padding: 20px;
    overflow: hidden;
  }
  
  /* Slide Container */
  .slider-container2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 0px 70px;
    padding-bottom: 30px;
    border-radius: 15px;
    margin-bottom: 20px;
    overflow: hidden; /* Prevents overflow */
  }
  
  /* Text Content Styling */
  .slider-content2 {
    color: #626261;
    text-align: left;
    flex: 1; /* Allow text to take up space */
    margin-right: 20px; /* Add space between text and image */
    min-width: 300px; /* Minimum width to ensure space for text */
  }
  
  .heading2 {
    color: #007bff;
  }
  
  /* Image Styling */
  .image-container2 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slider-image2 {
    max-width: 74%; /* Ensure image scales with the container */
    height: auto;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  /* Initially hide the arrows */
  .carousel .control-prev.control-arrow,
  .carousel .control-next.control-arrow {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  
  /* Show arrows on hover */
  .poster2:hover .carousel .control-prev.control-arrow,
  .poster2:hover .carousel .control-next.control-arrow {
    opacity: 1; /* Show arrows when hovering */
    visibility: visible;
  }
  
  /* Arrow Styles */
  .carousel .control-prev.control-arrow,
  .carousel .control-next.control-arrow {
    background-color: white; /* White background for the circle */
    width: 40px; /* Size of the circle */
    height: 40px;
    z-index: 2;
    border-radius: 50%; /* Make the arrow button circular */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for the arrow */
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
  }
  
  /* Arrow Icons */
  .carousel .control-prev.control-arrow::before,
  .carousel .control-next.control-arrow::before {
    content: ""; /* Empty content for arrows */
    border: solid black; /* Black arrow color */
    border-width: 0 2px 2px 0; /* Adjust borders for arrow shape */
    display: inline-block;
    padding: 4px; /* Adjust padding to control arrow size */
  }
  
  /* Left arrow */
  .carousel .control-prev.control-arrow::before {
    transform: rotate(135deg); /* Rotate left arrow */
  }
  
  /* Right arrow */
  .carousel .control-next.control-arrow::before {
    transform: rotate(-45deg); /* Rotate right arrow */
  }
  
  .carousel .control-prev.control-arrow {
    left: 10px; /* Adjust this value to move the arrow further left */
  }
  
  .carousel .control-next.control-arrow {
    right: 10px; /* Position the right arrow on the far right */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .poster {
      width: 45%;
    }
  
    .slider-content {
      padding: 10px;
    }
  
    .heading {
      font-size: 1.5rem;
    }
  
    .description {
      font-size: 1rem;
    }
  }