.business-banner {
    display: flex;
    align-items: center;
    background-color: #b3ecff; /* Light blue background */
    padding: 40px;
    gap: 0px;
    border-radius: 10px;
    height: 433px;
    clip-path: ellipse(100% 100% at 50% 0%);
  }
  
  .business-banner-text {
    flex: 1;
    padding-right: 20px;
    padding-top: 0px;
    transform: translateY(-15%);
  }
  
  .business-banner-text h1 {
    text-align: center;
    font-size: 48px;
    color: #2e0c6e;
    margin: 0;
    padding: 0;
  }
  
  .business-banner-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .business-banner-image img {
    max-width: 75%;
    height: auto;
    border-radius: 10px;
  }
  