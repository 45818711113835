/* Import Google Fonts for this component */
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600;700&display=swap');

/* Define variables within component scope */
.haContainer {
  --bg-dark: #0a0a1a;
  --neon-cyan: #00faff;
  --neon-magenta: #ff00ff;
  --neon-lime: #00ff99;
  --text-light: #e0e0ff;
  --panel-bg: rgba(10, 10, 50, 0.9);
  --space-gradient: linear-gradient(45deg, #1a1a3a, #0a0a1a);
  --border-color: rgba(255, 255, 255, 0.1);
  --primary-color: #00faff;
  --success-color: #00ff99;
  --error-color: #ff00ff;
  --highlight-color: yellow;

  font-family: 'Space Grotesk', sans-serif;
  background: var(--space-gradient);
  color: var(--text-light);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
}

.haContainer[data-theme="light"] {
  --bg-dark: #f0f0f0;
  --text-light: #333333;
  --panel-bg: #ffffff;
  --space-gradient: linear-gradient(45deg, #f8f9fa, #e9ecef);
  --border-color: #e0e0e0;
  --primary-color: #0066cc;
  --success-color: #28a745;
  --neon-cyan: #0066cc;
  --neon-magenta: #d63384;
  --neon-lime: #28a745;
  --error-color: #d63384;
  --highlight-color: #fd7e14;
}

/* Component-specific styles */
.confettiCanvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1000;
}

.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 1rem 1.5rem;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 20px var(--neon-cyan);
  z-index: 100;
  position: relative;
}

.haContainer[data-theme="light"] .topBar {
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.topBarCenter {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--neon-lime);
}

.haContainer[data-theme="light"] .logo {
  color: #333333;
}

.logoIcon {
  color: var(--neon-lime);
}

.haContainer[data-theme="light"] .logoIcon {
  color: #333333;
}

.topBarActions {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-left: auto;
}

.themeToggle {
  padding: 0.6rem;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid var(--neon-cyan);
  color: var(--neon-cyan);
  cursor: pointer;
  transition: all 0.3s ease;
}

.haContainer[data-theme="light"] .themeToggle {
  background: #ffffff;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.themeToggle:hover {
  background: var(--neon-cyan);
  color: var(--bg-dark);
}

.haContainer[data-theme="light"] .themeToggle:hover {
  background: var(--primary-color);
  color: #ffffff;
}

.achievementsButton {
  padding: 0.6rem;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid var(--neon-cyan);
  color: var(--neon-cyan);
  cursor: pointer;
  transition: all 0.3s ease;
}

.haContainer[data-theme="light"] .achievementsButton {
  background: #ffffff;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.achievementsButton:hover {
  background: var(--neon-cyan);
  color: var(--bg-dark);
}

.mainContent {
  display: grid;
  grid-template-columns: 320px 1fr 300px;
  gap: 1.5rem;
  padding: 1.5rem;
  flex: 1;
  height: calc(100vh - 60px);
}

.leftPanel {
  background: var(--panel-bg);
  border-radius: 12px;
  padding: 1.2rem;
  box-shadow: 0 0 15px var(--neon-magenta);
  backdrop-filter: blur(10px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  height: 80vh;
}

.haContainer[data-theme="light"] .leftPanel {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.panelHeader {
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--border-color);
}

.headerTitle {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 1.2rem;
  color: var(--primary-color);
}

.exerciseNavigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.navButton {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.haContainer[data-theme="light"] .navButton {
  background: #ffffff;
}

.navButton:hover:not(:disabled) {
  background: var(--primary-color);
  color: var(--bg-dark);
}

.navButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.taskProgress {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-weight: 600;
  font-size: 0.9rem;
}

.haContainer[data-theme="light"] .taskProgress {
  background: #f8f9fa;
}

.instructions {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 1.5rem;
  border: 1px solid var(--neon-cyan);
}

.haContainer[data-theme="light"] .instructions {
  background: #f8f9fa;
  border: 1px solid var(--primary-color);
}

.instructionHeader {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 1.2rem;
}

.instructionHeader h3 {
  font-size: 1.2rem;
  font-weight: 600;
}

.instructionContent {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.taskHighlight {
  background: rgba(0, 250, 255, 0.1);
  border-radius: 8px;
  border: 1px solid var(--primary-color);
  overflow: hidden;
}

.haContainer[data-theme="light"] .taskHighlight {
  background: rgba(0, 102, 204, 0.05);
  border: 1px solid var(--primary-color);
}

.highlightHeader {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding: 0.8rem;
  background: rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid var(--primary-color);
  font-weight: 600;
  color: var(--primary-color);
}

.haContainer[data-theme="light"] .highlightHeader {
  background: rgba(0, 102, 204, 0.1);
}

.highlightContent {
  padding: 1rem;
  color: var(--text-light);
  font-size: 1rem;
  line-height: 1.5;
}

/* Highlighted text in instructions */
.highlightedText {
  background: rgba(255, 153, 0, 0.2);
  color: var(--highlight-color);
  padding: 0.1rem 0.3rem;
  border-radius: 4px;
  font-weight: 600;
  border: 1px dashed var(--highlight-color);
}

.haContainer[data-theme="light"] .highlightedText {
  background: rgba(253, 126, 20, 0.1);
  color: var(--highlight-color);
  border: 1px dashed var(--highlight-color);
}

.codeExample {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  border: 1px dashed var(--border-color);
  overflow: hidden;
}

.haContainer[data-theme="light"] .codeExample {
  background: #f1f3f5;
  border: 1px dashed #ced4da;
}

.codeHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  background: rgba(0, 0, 0, 0.2);
  border-bottom: 1px dashed var(--border-color);
}

.haContainer[data-theme="light"] .codeHeader {
  background: #e9ecef;
  border-bottom: 1px dashed #ced4da;
}

.codeLabel {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.6);
}

.haContainer[data-theme="light"] .codeLabel {
  color: #6c757d;
}

.copyButton {
  background: none;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  padding: 0.4rem;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.copyButton:hover {
  background: rgba(255, 255, 255, 0.1);
}

.haContainer[data-theme="light"] .copyButton:hover {
  background: rgba(0, 102, 204, 0.1);
}

.codeExample code {
  display: block;
  padding: 1rem;
  font-family: 'Courier New', monospace;
  font-size: 0.9rem;
}

.resourcesSection {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 1.2rem;
  border: 1px solid var(--neon-cyan);
}

.haContainer[data-theme="light"] .resourcesSection {
  background: #f8f9fa;
  border: 1px solid var(--primary-color);
}

.resourcesHeader {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.resourcesList {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.resourceLink {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding: 0.8rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  color: var(--text-light);
  text-decoration: none;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.haContainer[data-theme="light"] .resourceLink {
  background: #ffffff;
  border: 1px solid #e9ecef;
  color: #495057;
}

.resourceLink:hover {
  background: var(--primary-color);
  color: var(--bg-dark);
}

.haContainer[data-theme="light"] .resourceLink:hover {
  background: var(--primary-color);
  color: #ffffff;
}

.hintButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid var(--neon-cyan);
  border-radius: 8px;
  color: var(--neon-cyan);
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
}

.haContainer[data-theme="light"] .hintButton {
  background: #f8f9fa;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.hintButton:hover {
  background: var(--neon-cyan);
  color: var(--bg-dark);
}

.haContainer[data-theme="light"] .hintButton:hover {
  background: var(--primary-color);
  color: #ffffff;
}

.hintCount {
  padding: 0.2rem 0.6rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  font-size: 0.8rem;
}

.haContainer[data-theme="light"] .hintCount {
  background: #e9ecef;
}

.hintsContainer {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 1rem;
  border: 1px solid var(--success-color);
}

.haContainer[data-theme="light"] .hintsContainer {
  background: rgba(40, 167, 69, 0.05);
  border: 1px solid var(--success-color);
}

.hintItem {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding: 0.8rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  margin-bottom: 0.8rem;
  color: var(--success-color);
}

.haContainer[data-theme="light"] .hintItem {
  background: rgba(40, 167, 69, 0.1);
  color: #155724;
}

.hintItem:last-child {
  margin-bottom: 0;
}

.centerPanel {
  display: flex;
  flex-direction: column;
  background: var(--panel-bg);
  border-radius: 12px;
  box-shadow: 0 0 15px var(--neon-magenta);
  backdrop-filter: blur(10px);
  overflow: hidden;
  transition: all 0.3s ease;
  height: 80vh;
}

.haContainer[data-theme="light"] .centerPanel {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.centerPanel.correct {
  box-shadow: 0 0 20px var(--success-color);
}

.editorHeader {
  padding: 1rem;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
}

.haContainer[data-theme="light"] .editorHeader {
  background: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.editorHeader span {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-light);
  font-weight: 500;
}

.editorActions {
  display: flex;
  gap: 0.8rem;
}

.actionBtn {
  padding: 0.5rem 0.8rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  font-size: 0.9rem;
}

.haContainer[data-theme="light"] .actionBtn {
  background: #ffffff;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.actionBtn:hover {
  background: var(--primary-color);
  color: var(--bg-dark);
}

.haContainer[data-theme="light"] .actionBtn:hover {
  background: var(--primary-color);
  color: #ffffff;
}

.editorWrapper {
  flex: 1;
  overflow: hidden;
}

.editorFooter {
  padding: 1rem;
  background: rgba(0, 0, 0, 0.3);
  border-top: 1px solid var(--border-color);
}

.haContainer[data-theme="light"] .editorFooter {
  background: #f8f9fa;
  border-top: 1px solid #e9ecef;
}

.submitBtn {
  width: 100%;
  padding: 0.8rem;
  background: var(--success-color);
  color: var(--bg-dark);
  border: none;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  transition: all 0.3s ease;
}

.haContainer[data-theme="light"] .submitBtn {
  background: var(--success-color);
  color: #ffffff;
}

.submitBtn:hover {
  opacity: 0.9;
}

.rightPanel {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.resultSection {
  background: var(--panel-bg);
  padding: 1.2rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
}

.haContainer[data-theme="dark"] .resultSection {
  border: 1px solid var(--neon-cyan);
}

.haContainer[data-theme="light"] .resultSection {
  border: 1px solid #e9ecef;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.resultSection h3 {
  margin-bottom: 0.8rem;
  font-size: 1.1rem;
}

.resultPreview {
  min-height: 120px;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  box-shadow: inset 0 0 12px var(--neon-magenta);
  overflow: auto;
  font-size: 1rem;
  color: #ffffff;
}

.haContainer[data-theme="light"] .resultPreview {
  background: #f8f9fa;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  color: #212529;
}

.resultPreview * {
  color: inherit;
}

.feedbackModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.5rem;
  border-radius: 12px;
  backdrop-filter: blur(10px);
  z-index: 1000;
  text-align: center;
  animation: cosmicPulse 0.5s ease;
  width: 90%;
  max-width: 400px;
}

.success {
  background: rgba(0, 255, 153, 0.1);
  border: 2px solid var(--success-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.haContainer[data-theme="light"] .success {
  background: rgba(40, 167, 69, 0.1);
}

.error {
  background: rgba(255, 0, 85, 0.1);
  border: 2px solid var(--error-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.haContainer[data-theme="light"] .error {
  background: rgba(214, 51, 132, 0.1);
}

.successMessage h2,
.errorMessage h2 {
  font-size: 1.4rem;
  margin-bottom: 0.8rem;
}

.haContainer[data-theme="dark"] .feedbackModal h2,
.haContainer[data-theme="dark"] .feedbackModal p {
  color: #ffffff;
}

.haContainer[data-theme="light"] .feedbackModal h2,
.haContainer[data-theme="light"] .feedbackModal p {
  color: #333333;
}

.nextBtn {
  margin-top: 1rem;
  padding: 0.8rem 1.5rem;
  background: var(--success-color);
  color: var(--bg-dark);
  border: none;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.haContainer[data-theme="light"] .nextBtn {
  background: var(--success-color);
  color: #ffffff;
}

.nextBtn:hover {
  opacity: 0.9;
}

.errorMessage button {
  margin-top: 1rem;
  padding: 0.8rem 1.5rem;
  background: var(--primary-color);
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.errorMessage button:hover {
  opacity: 0.9;
}

@keyframes cosmicPulse {
  0% { transform: translate(-50%, -50%) scale(0.9); opacity: 0; }
  70% { transform: translate(-50%, -50%) scale(1.05); opacity: 1; }
  100% { transform: translate(-50%, -50%) scale(1); }
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: var(--panel-bg);
  border-radius: 12px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 0 20px var(--neon-magenta);
  animation: modalFadeIn 0.3s ease;
}

.haContainer[data-theme="light"] .modal {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.modalHeader {
  padding: 1.2rem;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalHeader h2 {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 1.2rem;
  color: var(--primary-color);
}

.closeButton {
  background: none;
  border: none;
  color: var(--text-light);
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.closeButton:hover {
  color: var(--error-color);
}

.modalContent {
  padding: 1.2rem;
}

.achievementsList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.achievementItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.achievementItem.unlocked {
  background: rgba(0, 255, 153, 0.1);
  border: 1px solid var(--success-color);
}

.achievementItem.locked {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--border-color);
  opacity: 0.7;
}

.haContainer[data-theme="light"] .achievementItem.unlocked {
  background: rgba(40, 167, 69, 0.1);
}

.haContainer[data-theme="light"] .achievementItem.locked {
  background: #f8f9fa;
  border: 1px solid #e9ecef;
}

.achievementIcon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  color: var(--primary-color);
}

.haContainer[data-theme="light"] .achievementIcon {
  background: #ffffff;
  border: 1px solid #e9ecef;
}

.achievementInfo {
  flex: 1;
}

.achievementInfo h3 {
  font-size: 1.1rem;
  margin-bottom: 0.3rem;
}

.achievementInfo p {
  font-size: 0.9rem;
  color: var(--text-light);
  opacity: 0.8;
}

.achievementUnlocked {
  color: var(--success-color);
}

.achievementLocked {
  color: var(--border-color);
}

@keyframes modalFadeIn {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}