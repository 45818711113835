/* Container for the platform */
.learningPlatform {
    font-family: Arial, sans-serif;
    width: 90%;
    margin: 0 auto;
    padding: 20px;
  }
  
  section {
    display: flex;
    align-items: center;
    gap: 10px; /* Add minimal gap for even spacing */
  }
  
  .content {
    flex: 1;
    padding: 0; /* Remove padding to align with the image */
  }
  
  .image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0; /* Ensure no extra padding here */
  }
  
  .image img {
    max-width: 75%; /* Adjust width */
    height: auto;
    display: block; /* Avoid extra space under the image */
  }
  
  h2 {
    color: #007bff;
    font-size: 32px;
    margin-bottom: 15px;
  }
  
  p {
    color: #15163d;
    font-size: 20px;            /* Changes */
    margin-bottom: 20px;
  }
  
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  li {
    color: #414166;
    font-size: 16px;
    margin-bottom: 8px;
    position: relative;
    padding-left: 20px;
  }
  
  li::before {
    content: "•";
    color: black;
    font-size: 18px;
    position: absolute;
    left: 0;
    top: -2px;
  }
  
  .blueRectangle {
    width: 80px;
    height: 12px;
    border-bottom-right-radius: 25px;
    background-color: #007bff;
    margin-bottom: 5px;
  }
  
  /* Ensure consistent content ordering */
  .learningExperience .content {
    order: 1;
  }
  
  .learningExperience .image {
    order: 2;
  }
  
  .boostEmployability .content {
    order: 2;
  }
  
  .boostEmployability .image {
    order: 1;
  }
  
  /* Responsive behavior for smaller screens */
  @media (max-width: 768px) {
    section {
      flex-direction: column;
      margin-left: 0;
    }
  
    .content {
      margin-left: 0;
    }
  
    .image img {
      max-width: 80%; /* Set image width to 80% for smaller screens */
    }
  
    h2 {
      font-size: 28px; /* Smaller heading for mobile */
    }
  
    p {
      font-size: 18px; /* Smaller paragraph for mobile */
    }
  
    li {
      font-size: 14px; /* Smaller list items for mobile */
    }
  }
  