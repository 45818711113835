.work-section {
    text-align: center;
    background-color: #ffffff;
    padding: 50px 20px;
  }
  
  .work-heading {
    font-size: 42px;
    color: #1f1f1f;
    margin-bottom: 50px;
    font-weight: bold;
  }
  
  .work-content {
    position: relative;
    display: flex;
    justify-content: center; /* Adjusted to reduce the gap */
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .snake-design {
    position: absolute;
    width: 100%;
    top: 38%;
    transform: translateY(-50%);
    z-index: 1;
  }
  
  .work-step {
    position: relative;
    text-align: center;
    width: 220px; /* Adjusted width to reduce the gap */
    margin: 10px 5px; /* Reduced the margin */
    z-index: 2;
    padding: 15px; /* Reduced padding */
  }
  
  .lower .work-title, .lower .work-description {
    transform: translateY(30px); /* Adjust this value to move the titles and descriptions lower */
  }
  
  .work-circle {
    width: 175px; /* Adjusted width */
    height: 175px; /* Adjusted height */
    background-color: #e9e9e9;
    border-radius: 50%;
    margin: 0 auto 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 8px solid #387ebd;
  }
  
  .work-icon {
    width: 100px; /* Adjusted width */
    height: 100px; /* Adjusted height */
  }
  
  .work-title {
    font-size: 19px; /* Adjusted font size */
    font-weight: bold;
    margin-bottom: 5px;
    color: #1f1f1f;
  }
  
  .work-description {
    font-size: 16px; /* Adjusted font size */
    color: #6f6f6f;
    line-height: 1.4; /* Adjusted line height */
    padding: 0 10px;
  }
  