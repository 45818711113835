body {
    font-family: Arial, sans-serif;
    margin: 0;
    overflow-x: hidden; /* Prevent horizontal scroll */
  }
  
  .animated-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 75px;
    padding: 100px 20px;
  }
  
  .animated-section {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the sections */
    width: 100%;
    max-width: 900px;
    position: relative; /* To enable relative adjustments */
  }
  
  .animated-content {
    flex: 1;
    opacity: 0; /* Initially hidden */
    transition: all 0.8s ease-in-out;
    position: relative; /* Separate its position from others */
    top: -45px; /* Move only the heading and description upwards */
  }
  
  .animated-content.left {
    text-align: left;
    transform: translateX(-100%); /* Slide in from left */
  }
  
  .animated-content.right {
    text-align: left;
    transform: translateX(100%); /* Slide in from right */
    margin-left: 570px;
  }
  
  .animated-content.visible {
    opacity: 1;
    transform: translateX(0); /* Final position */
  }
  
  .animated-content h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .animated-content p {
    font-size: 18px;
    color: #555;
  }
  
  .animated-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute; /* Allow precise positioning */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally relative to the parent */
    transform: translate(-50%, -50%); /* Ensure perfect centering */
    width: 20px; /* Adjust width to suit your layout */
  }
  
  .animated-icon {
    font-size: 45px;
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .divider {
    width: 2px;
    height: 75px; /* Adjust height to control spacing */
    background-color: #ccc;
    margin: 10px 10px; /* Center the divider horizontally */
  }
  