.connect-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url('../../assets/images/connect.svg') no-repeat center center;
    background-size: 80%; /* Reduced the size of the background image */
    text-align: center;
  }
  
  .content {
    max-width: 600px;
    margin-left: 110px; /* Shifted the body text slightly to the right */
    margin-bottom: 30px;
    color: #2e0c6e;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  }
  
  .heading {
    font-size: 54px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .highlight {
    position: relative;
    display: inline-block;
    color: #2e0c6e;
  }
  
  .highlight::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 10px;
    width: 100%;
    background-color: orange;
    z-index: -1;
    transform: rotate(-3deg); /* Added slight sketch pen effect */
  }
  
  .subheading {
    font-size: 24px;
    font-weight: 500;
    color: #333;
    margin-bottom: 16px;
    text-shadow: none; /* Removed shadow from the subheading */
  }
  
  .description {
    font-size: 17px;
    line-height: 1.6;
    color: #666;
    margin-bottom: 20px;
    text-shadow: none; /* Removed shadow from the paragraph */
  }
  
  .connect-button {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s, box-shadow 0.2s;
    width: 200px;
  }
  
  .connect-button:hover {
    background-color: #007bff;
    transform: scale(1.05);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
  }
  