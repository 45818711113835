.hlayout-root {
  /* This class will be the wrapper for the entire component */
  --primary-color: #4f46e5;
  --secondary-color: #6366f1;
  --success-color: #10b981;
  --warning-color: #f59e0b;
  --danger-color: #dc2626;
  --light-bg: #f0f7ff;
  --text-dark: #1e293b;
  --border-color: #e2e8f0;
  --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);
  --radius-sm: 8px;
  --radius-md: 12px;
  --radius-lg: 16px;

  font-family: "Inter", sans-serif;
  line-height: 1.5;
  color: var(--text-dark);
  background-color: var(--light-bg);
}

.hlayout-page-wrapper {
  min-height: 100vh;
  background-color: var(--light-bg);
  transition: filter 0.3s ease;
  position: relative;
}

.hlayout-blur-background .hlayout-course-container {
  filter: blur(5px);
}

.hlayout-blur-background {
  height: 100vh;
  overflow: hidden;
}

.hlayout-course-container {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}

.hlayout-left-panel {
  position: sticky;
  top: 2rem;
  height: calc(100vh - 4rem);
  width: 350px;
  padding: 1.5rem;
  background: white;
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-md);
  overflow-y: auto;
}

.hlayout-panel-header {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid var(--border-color);
}

.hlayout-course-header-top {
  margin-bottom: 1.5rem;
}

.hlayout-course-heading {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.hlayout-course-icon {
  font-size: 1.8rem;
  margin-right: 1rem;
  color: var(--primary-color);
}

.hlayout-course-meta {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

.hlayout-meta-item {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.9rem;
  color: #64748b;
}

.hlayout-search-bar {
  position: relative;
  margin: 1rem 0;
}

.hlayout-search-bar input {
  width: 100%;
  padding: 0.75rem 2rem;
  border: 1px solid var(--border-color);
  border-radius: var(--radius-sm);
  background: #f8fafc;
  font-size: 0.95rem;
  transition: all 0.3s ease;
}

.hlayout-search-bar input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
}

.hlayout-search-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #64748b;
}

.hlayout-course-section {
  margin-bottom: 1rem;
  position: relative;
}

.hlayout-section-header {
  position: relative;
  background: #ffffff;
  border-radius: var(--radius-md);
  margin-bottom: 1rem;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.hlayout-section-header::before {
  content: "";
  position: active;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid transparent;
  border-radius: inherit;
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  pointer-events: none;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.hlayout-section-header:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.hlayout-section-header:hover::before {
  opacity: 1;
}

.hlayout-section-header-active {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  color: white;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.hlayout-section-header-active::before {
  opacity: 0;
}

.hlayout-section-header-content {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.hlayout-section-icon-container {
  position: relative;
  margin-right: 1rem;
}

.hlayout-section-icon-main {
  font-size: 1.5rem;
  color: var(--primary-color);
  transition: color 0.2s ease;
}

.hlayout-section-header-active .hlayout-section-icon-main {
  color: white;
}

.hlayout-section-badge {
  position: absolute;
  bottom: -5px;
  right: -5px;
  color: var(--success-color);
  font-size: 1.2rem;
  background: white;
  border-radius: 50%;
  padding: 2px;
  box-shadow: var(--shadow-sm);
}

.hlayout-section-chevron {
  font-size: 1.2rem;
  color: #94a3b8;
  margin-right: 1rem;
  transition: transform 0.3s ease, color 0.3s ease;
}

.hlayout-section-chevron-rotated {
  transform: rotate(90deg);
}

.hlayout-section-title-wrapper {
  flex: 1;
}

.hlayout-section-title {
  margin: 0;
  font-size: 1.15rem;
  font-weight: 600;
  color: var(--text-dark);
  transition: color 0.2s ease;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.hlayout-section-header-active .hlayout-section-title {
  color: white;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

.hlayout-section-progress {
  width: 100%;
  padding: 0 1rem;
  margin: 0.5rem 0 1rem;
}

.hlayout-progress-text {
  display: block;
  font-size: 0.85rem;
  color: #64748b;
  margin-bottom: 0.3rem;
}

.hlayout-section-progress-bar {
  width: 100%;
  height: 10px;
  background: #e0e7ff;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.hlayout-section-progress-fill {
  background: linear-gradient(90deg, var(--primary-color), var(--secondary-color));
  height: 100%;
  border-radius: 5px;
  transition: width 0.3s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.hlayout-topics {
  margin: 0.5rem 0;
  padding: 0 1rem 1rem 1rem;
}

.hlayout-topic {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin: 0.5rem 0;
  background: white;
  border-radius: var(--radius-sm);
  box-shadow: var(--shadow-sm);
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid var(--border-color);
}

.hlayout-topic:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
  border-color: var(--primary-color);
}

.hlayout-topic-active {
  border-left: 4px solid var(--primary-color);
  background: #f8fafc;
}

.hlayout-topic-completed {
  border-left: 4px solid var(--success-color);
  background: #f8fafc;
}

.hlayout-topic-completed .hlayout-topic-name {
  color: var(--success-color);
}

.hlayout-topic-status {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  cursor: pointer;
  transition: transform 0.2s;
}

.hlayout-topic-check {
  color: var(--success-color);
  font-size: 1.4rem;
  animation: hlayout-bounce 0.5s;
}

.hlayout-topic-circle {
  width: 20px;
  height: 20px;
  border: 2px solid #94a3b8;
  border-radius: 50%;
  position: relative;
  transition: border-color 0.2s;
}

.hlayout-topic-circle:hover {
  border-color: var(--primary-color);
}

.hlayout-topic-content {
  flex-grow: 1;
}

.hlayout-topic-name {
  display: block;
  font-weight: 500;
  color: var(--text-dark);
  margin-bottom: 0.25rem;
}

.hlayout-topic-meta {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1rem;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.hlayout-topic-duration,
.hlayout-topic-lessons {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #64748b;
}

.hlayout-topic-sparkle {
  color: var(--warning-color);
  animation: hlayout-sparkle 1.5s infinite;
}

.hlayout-topic-arrow {
  color: #94a3b8;
  width: 20px;
}

.hlayout-right-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.hlayout-content-container {
  background: #4169e1;
  border-radius: var(--radius-lg);
  padding: 2rem;
  position: relative;
  height: 440px;
  color: white;
  margin-bottom: 0.6rem;
  overflow: hidden;
}

.hlayout-content-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.hlayout-brand {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.hlayout-brand-logo {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.hlayout-brand-name {
  font-size: 2rem;
  font-weight: 500;
  color: white;
  font-family: "Gill Sans Extrabold", sans-serif;
}

.hlayout-content-body {
  position: relative;
  z-index: 1;
}

.hlayout-content-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: white;
}

.hlayout-content-list {
  margin-bottom: 1.5rem;
}

.hlayout-content-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  color: white;
}

.hlayout-check-icon {
  margin-right: 0.5rem;
}

.hlayout-get-started-btn {
  background: white;
  color: #4169e1;
  border: none;
  padding: 1rem 3rem;
  font-size: 1.2rem;
  font-weight: 700;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.35);
}

.hlayout-get-started-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
}

.hlayout-illustration {
  position: absolute;
  right: 1.5rem;
  bottom: 4rem;
  width: 320px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hlayout-illustration-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.hlayout-action-buttons {
  display: flex;
  gap: 1rem;
  background: white;
  border-radius: var(--radius-lg);
  margin: 0;
  box-shadow: var(--shadow-md);
}

.hlayout-action-btn {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1.3rem 1rem;
  border: none;
  border-radius: var(--radius-md);
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  background: transparent;
  color: #4a5568;
}

.hlayout-action-btn:hover {
  background: #f7fafc;
  transform: translateY(-2px);
}

.hlayout-action-icon {
  width: 20px;
  height: 20px;
}

.hlayout-video-btn {
  color: #2563eb;
}

.hlayout-quiz-btn {
  color: #7c3aed;
}

.hlayout-plan-btn {
  color: #059669;
}

.hlayout-share-btn {
  color: #dc2626;
}

.hlayout-installation-guide {
  background: white;
  border-radius: var(--radius-lg);
  padding: 2rem;
  box-shadow: var(--shadow-md);
}

.hlayout-guide-tabs {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.hlayout-guide-tabs .hlayout-tab {
  padding: 0.75rem 1.5rem;
  border: none;
  background: #e2e8f0;
  border-radius: var(--radius-sm);
  cursor: pointer;
  transition: all 0.3s ease;
}

.hlayout-guide-tabs .hlayout-tab-active {
  background: var(--primary-color);
  color: white;
}

.hlayout-installation-list {
  list-style: none;
  counter-reset: item;
  padding-left: 0;
}

.hlayout-installation-list li {
  counter-increment: item;
  position: relative;
  padding-left: 2em;
  margin-bottom: 0.5em;
}

.hlayout-installation-list li::before {
  content: counter(item) " ";
  position: absolute;
  left: 0;
  top: 0;
  font-weight: bold;
}

.hlayout-achievement-container {
  position: fixed;
  top: 2rem;
  right: 2rem;
  z-index: 1000;
}

.hlayout-achievement-toast {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  color: white;
  padding: 1rem 2rem;
  border-radius: 50px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  animation: hlayout-slideIn 0.3s ease-out;
  box-shadow: var(--shadow-lg);
}

.hlayout-achievement-icon {
  color: #fcd34d;
  font-size: 1.2rem;
}

@keyframes hlayout-slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes hlayout-bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes hlayout-sparkle {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
    transform: rotate(30deg);
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .hlayout-course-container {
    flex-direction: column;
    padding: 1rem;
  }

  .hlayout-left-panel {
    position: relative;
    height: auto;
    width: 100%;
  }

  .hlayout-content-container {
    height: auto;
    padding-bottom: 4rem;
  }

  .hlayout-content-title {
    font-size: 2rem;
  }

  .hlayout-action-buttons {
    flex-wrap: wrap;
  }

  .hlayout-action-btn {
    flex: 1 1 45%;
  }

  .hlayout-achievement-toast {
    width: 90%;
    right: 5%;
    left: 5%;
    text-align: center;
  }

  .hlayout-guide-tabs {
    flex-wrap: wrap;
  }

  .hlayout-guide-tabs .hlayout-tab {
    flex: 1 1 100%;
    margin-bottom: 0.5rem;
  }

  .hlayout-achievement-container {
    top: 1rem;
    right: 1rem;
  }

  .hlayout-achievement-toast {
    padding: 0.75rem 1.5rem;
    font-size: 0.9rem;
  }
}

.hlayout-slides-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}

.hlayout-placeholder-slide {
  background: white;
  padding: 2rem;
  border-radius: var(--radius-lg);
  max-width: 80%;
  text-align: center;
}

.hlayout-placeholder-slide h2 {
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.hlayout-placeholder-slide button {
  margin-top: 1rem;
  padding: 0.5rem 1.5rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: var(--radius-sm);
  cursor: pointer;
}