.values-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px 40px;
  background-color: #f0f4f8;
  background-color: white;
  text-align: left;
  margin-top: 0px;
}

.value-card {
  position: relative;
  width: 20%;
  min-width: 371px;
  height: 178px;
  padding: 20px;
  border-radius: 10px;
  color: #ffffff;
  margin-top: 70px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.value-card:nth-child(1) {
  background-color: #4285F4;
}

.value-card:nth-child(2) {
  background-color: #2CC302;
}

.value-card:nth-child(3) {
  background-color: #975AB6;
}

.value-card:nth-child(4) {
  background-color: #FB8C00;
}

.value-card:nth-child(5) {
  background-color: #FBBC05;
}

.value-icon {
  position: absolute;
  top: -90px;
  left: 50%;
  transform: translateX(-50%);
  width: 260px;
  height: 160px;
  padding: 10px;
}

.value-card h3 {
  font-size: 1.5rem;
  margin: 40px 0 10px;
}

.value-card p {
  font-size: 1rem;
  color: #e0e0e0;
}
